
import { defineComponent } from 'vue';

import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Kinderzeitung',
  components: {
    TabGroup,
    Tab,
  },
});
